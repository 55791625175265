import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthenticationService } from './services/authenticationService.service';
import { RouterModule } from '@angular/router';
import { routes } from './authentication-routing.module';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { SelectPersonComponent } from './components/select-person/select-person.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LocalDataService } from '../services/localData.service';
import { ApiService } from '../services/api.service';

@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        SelectCompanyComponent,
        SelectPersonComponent
    ],
    exports: [
        RouterModule
    ], imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule.forRoot(),
        RouterModule.forRoot(routes)],
    providers: [
        AuthenticationService,
        LocalDataService,
        ApiService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AuthenticationModule { }
