import { Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SelectPersonComponent } from './components/select-person/select-person.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';

export const routes: Routes = [
  {
    path: 'authentication',
    component: AuthenticationComponent,
    children: [
      { path: 'login', component: LoginComponent, data: { returnUrl: window.location.pathname } },
      { path: 'register', component: RegisterComponent },
      { path: 'select-person', component: SelectPersonComponent },
      { path: 'select-company', component: SelectCompanyComponent },
      { path: '**', redirectTo: 'login' }, // Handle invalid child routes
    ],
  },
];
