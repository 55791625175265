<!-- login form -->
<form class="my-8 space-y-10" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="text-left">
        <h2 class="mb-1 text-2xl font-semibold text-gray-700 dark:text-white">Inloggen op <br> Autotaxatie Partners</h2>
    </div>

    <div class="space-y-3 text-left">
        <div class="form__group">
            <div class="relative">
                <label for="login_type" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Login
                    niveau</label>
                <select formControlName="login_type" id="login_type"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="company" selected>Bedrijf</option>
                    <option value="person">Persoon</option>
                </select>
            </div>
        </div>

        <div class="form__group">
            <div class="relative">
                <input type="text" id="username" [ngClass]="{ 'is__invalid-input': submitted && f['username'].errors }"
                    class="peer block" placeholder=" " formControlName="username" />
                <label for="username"
                    class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-95 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-95 peer-focus:px-2 peer-focus:text-primary-500 dark:bg-night-700 dark:text-night-200">
                    Gebruikersnaam
                </label>
            </div>
            <div *ngIf="submitted && f['username'].errors" class="is__invalid-error">
                <div *ngIf="f['username'].errors['username']">Dit is een verplicht veld</div>
                <div *ngIf="f['username'].errors['username']">Email must be an email address valid</div>
            </div>
        </div>

        <div class="form__group">
            <div class="relative">
                <input [type]="passwordTextType ? 'text' : 'password'" id="password"
                    [ngClass]="{ 'is__invalid-input': submitted && f['password'].errors }" class="peer block"
                    placeholder=" " formControlName="password" />
                <label for="password"
                    class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-95 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-95 peer-focus:px-2 peer-focus:text-primary-600 dark:bg-night-700 dark:text-night-200">
                    Wachtwoord
                </label>
                <span class="absolute top-2.5 right-5 cursor-pointer text-gray-400 dark:text-night-300"
                    (click)="togglePasswordTextType()">
                </span>
            </div>
            <div *ngIf="submitted && f['password'].errors" class="is__invalid-error">
                <div *ngIf="f['password'].errors['required']">Dit is een verplicht veld</div>
            </div>
        </div>
    </div>

    <div class="flex items-center justify-between space-x-3">
        <div class="flex items-center">
            <input formControlName="remember_me" id="remember-me" name="remember-me" type="checkbox" />
            <label for="remember-me" class="ml-2 block text-sm text-gray-400 dark:text-night-200"> Inloggegevens
                onthouden </label>
        </div>

        <div class="text-sm">
            <a routerLink="/auth/forgot-password" class="font-medium text-primary-500 hover:text-primary-400">
                Wachtwoord vergeten?
            </a>
        </div>
    </div>

    <p *ngIf="offlineDataLoading">Bezig met offline data ophalen....</p>

    <button
        class="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-500 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 hover:bg-primary-400">
        <svg *ngIf="loading" aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB" />
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor" />
        </svg>
        Inloggen
    </button>
</form>